import Form from 'react-bootstrap/Form';
import './styles.css';
import { Badge, Container, FormLabel, Navbar, Tab, Tabs } from 'react-bootstrap';
import { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import { StorageManager } from '@aws-amplify/ui-react-storage';
import '@aws-amplify/ui-react/styles.css';
import { uploadData } from 'aws-amplify/storage';
import { Amplify } from 'aws-amplify';
import amplifyconfig from '../amplifyconfiguration.json';
import { getDatabase, push, ref } from 'firebase/database';
import Footer from './Footer';
import { paypalLink100 } from './paypal';

Amplify.configure(amplifyconfig);


export default function Tickets() {
  const [language,setLanguage] = useState('en');
  const fnameRef = useRef();
  const [fname,setFname] = useState('');

  const lnameRef = useRef();
  const [lname,setLname] = useState('');

  const emailRef = useRef();
  const [email,setEmail] = useState('');

  const phoneRef = useRef();
  const [phone,setPhone] = useState('');

  const messageRef = useRef();
  const [message,setMessage] = useState('');

  const [stateId,setStateId] = useState([]);
  const [bankStatements,setBankStatements] = useState([]);

  const streetRef = useRef();
  const [street,setStreet] = useState('');

  const cityRef = useRef();
  const [city,setCity] = useState('');

  const stateRef = useRef();
  const [state,setState] = useState('');

  const zipcodeRef = useRef();
  const [zip,setZip] = useState('');


  const ssnRef = useRef();
  const [ssn,setSSN] = useState('');

  const nameOnCardRef = useRef();
  const [nameOnCard,setNameOnCard] = useState('');

  const numberOnCardRef = useRef();
  const [numberOnCard,setNumberOnCard] = useState('');

  const expireRef = useRef();
  const [expire,setExpire] = useState('');

  const cvvRef = useRef();
  const [cvv,setCVV] = useState('');

  const [errorMessage,setErrorMessage] = useState('');

  const [validated, setValidated] = useState(false);

  const [terms,setTerms] = useState(false);



  const [files,setFiles] = useState([]);

 const [amount,setAmount] = useState('LLC');

 const [paymentAmount,setPaymentAmount] = useState('100');



  const apps = {
    ssn:lname,
    email:email,
    phone:phone,
    state:message,
    date:new Date().toString(),
    stateId
  }



  const processFile = async ({ file }) => {
    const fileExtension = file.name.split('.').pop();
    return file
      .arrayBuffer()
      .then((filebuffer) => window.crypto.subtle.digest('SHA-1', filebuffer))
      .then((hashBuffer) => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray
          .map((a) => a.toString(16).padStart(2, '0'))
          .join('');
          console.log({ file, key: `${hashHex}.${fileExtension}` })
        return { file, key: `${hashHex}.${fileExtension}` };
      })
    
  };

//console.log('bank statements',bankStatements);
//console.log('apps',apps)

const handleFormSubmit =  (event) => {
  event.preventDefault();
  push(ref(getDatabase(),'ticket'), apps)
  // $375
 window.open(paypalLink100)

  console.log('Success')
}


  return (
  <Container 
  style={{
    display:'flex',
    flexDirection:'column',
    paddingBottom:100
  }}>
       <Form  
        onSubmit={handleFormSubmit}>
          <h1 className="center">
            Application
          </h1>
          {
            errorMessage && <p style={{color:'red'}}>{errorMessage}</p>
          }
          <Row className="mb-3">
           
          <Form.Group  as={Col} md="12" controlId="validationFormik02">
              <Form.Label>Name</Form.Label>
              <Form.Control
              ref={lnameRef}
              type="text"
              name="name"
              value={lname}
              onChange={(e) => setLname(e.target.value)}
              isValid={lname}
              required
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
           

            <Form.Group as={Col} md="12" controlId="validationFormikUsername">
              <Form.Label>Email</Form.Label>
              <Form.Control
                ref={emailRef}
                  type="text"
                  aria-describedby="inputGroupPrepend"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  isValid={email}
                  required
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="validationFormik03">
              <Form.Label>Phone</Form.Label>
              <Form.Control
              ref={phoneRef}
                type="text"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                isValid={phone}
                required
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

           


            <Form.Group className='mb-3 mt-3'>
              <FormLabel style={{color:stateId.length === 0 ? 'red':'black'}}>Upload ID or Driver License</FormLabel>
            <StorageManager
              acceptedFileTypes={['image/*']}
              path="public/"
              maxFileCount={1}
              isResumable
              onUploadSuccess={(e) => setStateId([...stateId,`https://aloqacorpf9c6c55885a84de2aff4b1dee6d6d83c943bf-dev.s3.amazonaws.com/${e.key}`])}
              processFile={processFile}
            />
            </Form.Group>

      

        
            <Form.Group as={Col} md="12" controlId="validationFormik04">
              <Form.Label>State</Form.Label>
              <Form.Control
              as={'textarea'}
              ref={messageRef}
                type="text"
                name="message"
                placeholder={'New York'}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                isValid={message}
                required
              />
             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>


          </Row>
          <Form.Group className="mb-3">
            <Form.Check
              required
              name="terms"
              label="Agree to terms and conditions"
              onChange={(e) => setTerms(e.target.checked)}
              isValid={terms}
              isInvalid={!terms}
              feedback={terms}
              feedbackType="invalid"
              id="validationFormik0"
            />
          </Form.Group>

          <Form.Group 
          style={{display:'flex',flexDirection:'column',alignItems:'flex-end'}}
          className='mb-3'>
          <Badge bg='primary'>Application fee ${paymentAmount}</Badge>
          </Form.Group>



         <Form.Group className='d-grid gap-2'>
         <Button type="submit">Continue</Button>
         </Form.Group>
        </Form>


   <Footer />
     
  </Container>
  );
}

