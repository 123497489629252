import { getDatabase, onValue, push, ref, remove } from 'firebase/database';
import { useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Stack from 'react-bootstrap/Stack';
import { FaTrash } from "react-icons/fa";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { IoCopyOutline } from "react-icons/io5";

export default function DriverJobApps() {
    const [apps, setApps] = useState([]);
    const [searchEmail, setSearchEmail] = useState('');
    const [filteredApps, setFilteredApps] = useState([]);

    const fetchAppsCB = useCallback(() => {
        onValue(ref(getDatabase(), `driverjobs`), (dataSnapshot) => {
            let dataArray = [];
            dataSnapshot.forEach(data => {
                dataArray.push(data);
                console.log(data.val());
            });
            setApps(dataArray.reverse());
        });
    }, []);

    useEffect(() => {
        fetchAppsCB();
    }, [fetchAppsCB]);

    useEffect(() => {
        if (searchEmail) {
            const filtered = apps.filter(item => 
                item?.val()?.email?.toLowerCase().includes(searchEmail.toLowerCase())
            );
            setFilteredApps(filtered);
        } else {
            setFilteredApps(apps);
        }
    }, [searchEmail, apps]);

    function removeItem(item) {
        remove(ref(getDatabase(), `driverjobs/${item?.key}`));
        alert('Deleted successfully');
    }

    return (
        <Container>
            <h1>Driver Apps</h1>
            <div className="search-bar" style={{ marginBottom: '20px' }}>
                <input
                    type="text"
                    placeholder="Search by email"
                    value={searchEmail}
                    onChange={(e) => setSearchEmail(e.target.value)}
                    style={{
                        width: '100%',
                        padding: '10px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                    }}
                />
            </div>
            {
                filteredApps?.map((item, index) => (
                    <Stack
                        key={index}
                        gap={3}
                        className='stack'
                    >
                        <div className="p-2">
                            <div className='my-row'>
                                <p>
                                    <FaTrash onClick={() => removeItem(item)} />
                                </p>
                            </div>
                            <p>
                                EMAIL: {item?.val()?.email}
                                <CopyToClipboard text={item?.val()?.email}
                                    onCopy={() => console.log(item?.val()?.email)}>
                                    <IoCopyOutline />
                                </CopyToClipboard>
                            </p>
                            <p>
                                PHONE: {item?.val()?.phone}
                                <CopyToClipboard text={item?.val()?.phone}
                                    onCopy={() => console.log(item?.val()?.phone)}>
                                    <IoCopyOutline />
                                </CopyToClipboard>
                            </p>
                            <p>
                                SSN: {item?.val()?.ssn}
                                <CopyToClipboard text={item?.val()?.ssn}
                                    onCopy={() => console.log(item?.val()?.ssn)}>
                                    <IoCopyOutline />
                                </CopyToClipboard>
                            </p>
                            {item?.val()?.driverLicenseBack ? (
                                <div>
                                    <div>
                                        <img
                                            src={item?.val()?.driverLicenseBack}
                                            alt="Driver License Back"
                                            style={{ width: 350, height: 350, marginBottom: '10px' }}
                                        />
                                        <br />
                                        <a
                                            href={item?.val()?.driverLicenseBack}
                                            download="Driver_License_Back.jpg"
                                            style={{
                                                textDecoration: 'none',
                                                padding: '10px',
                                                backgroundColor: '#007bff',
                                                color: 'white',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            Download Back
                                        </a>
                                    </div>
                                    <div style={{ marginTop: '20px' }}>
                                        <img
                                            src={item?.val()?.driverLicenseFront}
                                            alt="Driver License Front"
                                            style={{ width: 350, height: 350, marginBottom: '10px' }}
                                        />
                                        <br />
                                        <a
                                            href={item?.val()?.driverLicenseFront}
                                            download="Driver_License_Front.jpg"
                                            style={{
                                                textDecoration: 'none',
                                                padding: '10px',
                                                backgroundColor: '#007bff',
                                                color: 'white',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            Download Front
                                        </a>
                                    </div>
                                </div>
                            ) : (
                                <p>
                                    <a href={item?.val()?.driverLicenseBack} download={true}>View Driver License Back</a>
                                    <a href={item?.val()?.driverLicenseFront} download={true}>View Driver License Front</a>
                                </p>
                            )}
                        </div>
                        <hr/>
                    </Stack>
                ))
            }
        </Container>
    );
}
