import React from "react";
import "./ConsultingFirm.css";
import { useNavigate } from "react-router";

const ConsultingFirm = () => {
    const navigate = useNavigate();
  return (
    <div className="consulting-firm-container">
      <header className="header">
        <h1>Expert Consulting Services</h1>
        <p>Empowering businesses to achieve their full potential.</p>
      </header>
      <div className="content">
        <div className="text-section">
          <h2>Why Choose Us?</h2>
          <p>
            We provide tailored solutions for startups and enterprises,
            combining innovative strategies with industry expertise to drive
            success. Our team of seasoned professionals is here to guide you
            every step of the way.
          </p>
          <button onClick={() => navigate('/ask')}  className="cta-button">Get Started</button>
        </div>
        <div className="image-section">
          <img
            src={require('./consulting.png')}
            alt="Consulting Illustration"
          />
        </div>
      </div>
    </div>
  );
};

export default ConsultingFirm;
