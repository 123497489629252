import ConsultingFirm from './ConsultingFirm';
import Footer from './Footer';
import Header from './Header';
import Services from './Services';

function HomePage() {
  return (
    <>
    <ConsultingFirm />
    {/* <Header />
    <Services />
    <Footer /> */}
    </>
  );
}

export default HomePage;